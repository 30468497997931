import React, { Component } from 'react';
import Header from './header/Headers';
import Slider from './slider/Slider';
import FirstContent from './contect/FirstContent';
import SecondContent from './contect/SecondContent';
import ThirdContent from './contect/ThirdContent';
import FourContent from './contect/FourContent';
import Footer from './footer/Footer';
import Contact from './footer/Contact';
import './Custom.css';
import './Responsive.css';
import ReactGA from 'react-ga4';

class App extends Component {
    componentDidMount() {
        // Google Analytics'i başlat
        ReactGA.initialize('G-1BV6XYZFCR'); // İzleme kodunuz burada
        ReactGA.send('pageview'); // İlk sayfa görüntüleme
    }

    render() {
        return (
            <div className="mainCode">
                <Header />
                <Slider />
                <FirstContent />
                <SecondContent />
                <ThirdContent />
                <FourContent />
                <Contact />
                <Footer />
            </div>
        );
    }
}

export default App;
