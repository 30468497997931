import React, { Component } from 'react';
import $ from "jquery";
import FirstWorks from './images/hnn-disticaret.jpg';
import SecondWorks from './images/reklam5-oguzhansengulcom.jpg';
import ThirdWorks from './images/path-oguzhansengulcom.png';
import FourWorks from './images/kmk.png';
class SecondContent extends Component {
    componentDidMount() {
            var divHeight = $('.works').height()
        $('.worker .row').css('height', divHeight)
    }
    render() {
        return (
                <div className="myjobs" id="works">
                    <div className="works">
                        <div className="container worker">
                            <div className="row align-items-center">
                                <div className="col-lg-7 mx-auto text-center slider-text">
                                    <h3>Companies I Work For
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="site-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 companies-padding">
                                    <div className="class-item d-flex align-items-center">
                                        <a rel="nofollow"
                                           target="_blank" href="https://www.kmk.net.tr/">
                                            <img src={FourWorks} />
                                        </a>
                                        <div className="class-item-text">
                                            <p>KMK Bilgi Teknolojileri</p>
                                            <span>Sr. Front-End Developer</span>
                                            <span>2022 - Next</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <a rel="nofollow"
                                           target="_blank" href="https://path.com.tr/">
                                            <img src={ThirdWorks} />
                                        </a>
                                        <div className="class-item-text">
                                            <p>Path Internet</p>
                                            <span>Sr. Front-End Developer</span>
                                            <span>2019 - 2022</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 companies-padding">
                                    <div className="class-item d-flex align-items-center">
                                        <a  rel="nofollow"
                                            target="_blank" href="https://www.reklam5.com/" >
                                            <img src={SecondWorks} />
                                        </a>
                                        <div className="class-item-text">
                                            <p>Reklam5</p>
                                            <span>Front-End Developer</span>
                                            <span>2014 - 2019</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <a target="_blank" rel="nofollow"
                                           href="https://www.kamperest.com/">
                                            <img src={FirstWorks} />
                                        </a>
                                        <div className="class-item-text">
                                            <p>HNN Dış Ticaret</p>
                                            <span>Front-End Developer</span>
                                            <span>2013 - 2014</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

        );
    }
}

export default SecondContent;
