import React, { Component } from 'react';
import $ from "jquery";
import OneProject from './images/akcaholding-oguzhansengulcom.jpg';
import TwoProject from './images/akfengyo-oguzhansengulcom.jpg';
import ThreeProject from './images/altinyatak-oguzhansengulcom.jpg';
import FourProject from './images/armaelektropanc-oguzhansengulcom.jpg';
import FiveProject from './images/astel-oguzhansengulcom.jpg';
import SixProject from './images/banat-oguzhansengulcom.jpg';
import SevenProject from './images/bayraktar-oguzhansengulcom.jpg';
import EightProject from './images/blog-caddesi-logo-oguzhansengul.jpg';
import NineProject from './images/claro-oguzhansengulcom.jpg';
import TenProject from './images/duk-oguzhansengul.com.jpg';
import ElevenProject from './images/elf-otomasyon-oguzhansengulcom.jpg';
import TwelveProject from './images/esan-oguzhansengulcom.jpg';
import ThirteenProject from './images/geokoleji-oguzhansengulcom.jpg';
import FourteenProject from './images/innova-oguzhansengulcom.jpg';
import FiveteenProject from './images/istanbul74-oguzhansengulcom.jpg';
import SixteenProject from './images/istanbul-akvaryum-oguzhansengulcom.jpg';
import SeventeenProject from './images/istanbulcerrahi-oguzhansengulcom.jpg';
import EightteenProject from './images/jusbox-oguzhansengul-com.jpg';
import NineteenProject from './images/karsan-oguzhansengulcom.jpg';
import TwentyProject from './images/makel-oguzhansengulcom.jpg';
import TwentyoneProject from './images/masaustu-kirtasiye-oguzhansengulcom.jpg';
import TwentytwoProject from './images/mogul-oguzhansengulcom.jpg';
import TwentythreeProject from './images/rcilan-oguzhansengulcom.jpg';
import TwentyfourProject from './images/trabzonspor-oguzhansengulcom.jpg';
import TwentyfiveProject from './images/warmhaus-oguzhansengulcom.jpg';
import TwentysixProject from './images/welder-watch-oguzhansengulcom.jpg';
import TwentysevenProject from './images/inforoom-oguzhansengulcom.jpg';
import TwentyalfaProject from './images/alfa-alacak-yonetimi-oguzhansengul-com.jpg';
import Itp from './images/itp.jpg';
import path1 from './images/kist-1.jpg';
import path2 from './images/anım-2.jpg';
import path3 from './images/kiko-3.jpg';
import path4 from './images/beddable-6.jpg';
import path5 from './images/chef-5.jpg';
import path6 from './images/godiva-4.jpg';
import path7 from './images/nita-toys-7.jpg';
import path8 from './images/sazy-8.jpg';
class ThirdContent extends Component {
    componentDidMount() {
        var divHeight = $('.works').height()
        $('.project .row').css('height', divHeight);
        $('.tabs-1').click(function (){
            $(this).addClass('tabsActive');
            $('.tabs-2').removeClass('tabsActive')
            $('.tabs-3').removeClass('tabsActive')
            $('#tabs-1').removeClass('d-none')
            $('#tabs-2').addClass('d-none')
            $('#tabs-3').addClass('d-none')
            $('.more').show();
        })
        $('.tabs-2').click(function (){
            $(this).addClass('tabsActive');
            $('.tabs-1').removeClass('tabsActive')
            $('.tabs-3').removeClass('tabsActive')
            $('#tabs-2').removeClass('d-none')
            $('#tabs-1').addClass('d-none')
            $('#tabs-3').addClass('d-none')
            $('.more').show();
        })
        $('.tabs-3').click(function (){
            $(this).addClass('tabsActive');
            $('.tabs-2').removeClass('tabsActive')
            $('.tabs-1').removeClass('tabsActive')
            $('#tabs-3').removeClass('d-none')
            $('#tabs-2').addClass('d-none')
            $('#tabs-1').addClass('d-none')
            $('.more').hide();
        })
        $('.more button').click(function () {
            $('.moreDetail').css('height', '100%')
        });
    }
    render() {
        return (
            <div className="myproject" id="projects">
                <div className="projects">
                    <div className="container project">
                        <div className="row align-items-center">
                            <div className="col-lg-7 mx-auto text-center slider-text">
                                <h3>Everyone needs a website.

                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="site-section">
                    <div className="container">
                        <div className="row  justify-content-center text-center mb-5">
                            <div className="col-md-8">
                            <h4 className="text-center">The projects I've been in.
                            </h4>
                            <p className="text-center col-description">Some of them are Freelance and Some of them are the projects I have been through thanks to the companies
                                I work with.</p>
                            </div>
                            <div className="tabs mt-5">
                                <div className="col-md-4 text-center">
                                    <a href="javascript:void(0)"  className="tabs-2 tabsActive">E-Commerce</a>
                                </div>
                                <div className="col-md-4 text-center">
                                    <a href="javascript:void(0)"  className="tabs-1">Corporate</a>
                                </div>
                                <div className="col-md-4 text-center">
                                    <a href="javascript:void(0)" className="tabs-3">My Own Projects</a>
                                </div>
                            </div>
                            <div className="corporate mt-5 moreDetail d-none" id="tabs-1">
                                <div className="col-lg-6 col-md-6">
                                    <div className="class-item d-flex align-items-center">
                                        <img src={TwentyalfaProject} />
                                        <div className="class-item-text">
                                            <p>Alfa Alacak Yönetimi</p>
                                            <span>Freelance</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={OneProject} />
                                        <div className="class-item-text">
                                            <p>Akça Holding</p>
                                            <span>Reklam5</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={TwoProject} />
                                        <div className="class-item-text">
                                            <p>Akfen GYO</p>
                                            <span>Reklam5</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={FourProject} />
                                        <div className="class-item-text">
                                            <p>Arma Elektropanç</p>
                                            <span>Reklam5</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={FiveProject} />
                                        <div className="class-item-text">
                                            <p>Astel</p>
                                            <span>Reklam5</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={SixProject} />
                                        <div className="class-item-text">
                                            <p>Banat</p>
                                            <span>Reklam5</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={SevenProject} />
                                        <div className="class-item-text">
                                            <p>Bayraktar</p>
                                            <span>Reklam5</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={NineProject} />
                                        <div className="class-item-text">
                                            <p>Claro</p>
                                            <span>Reklam5</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={ElevenProject} />
                                        <div className="class-item-text">
                                            <p>Elf Otomasyon</p>
                                            <span>Freelance</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={TwelveProject} />
                                        <div className="class-item-text">
                                            <p>Esan</p>
                                            <span>Reklam5</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={ThirteenProject} />
                                        <div className="class-item-text">
                                            <p>GEO Koleji</p>
                                            <span>Reklam5</span>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="class-item d-flex align-items-center">
                                        <img src={EightteenProject} />
                                        <div className="class-item-text">
                                            <p>Jusbox</p>
                                            <span>Freelance</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={FiveteenProject} />
                                        <div className="class-item-text">
                                            <p>İstanbul 74</p>
                                            <span>Reklam5</span>
                                        </div>
                                    </div>

                                    <div className="class-item d-flex align-items-center">
                                        <img src={FourteenProject} />
                                        <div className="class-item-text">
                                            <p>Innova</p>
                                            <span>Reklam5</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={SeventeenProject} />
                                        <div className="class-item-text">
                                            <p>İstanbul Cerrahi</p>
                                            <span>Reklam5</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={NineteenProject} />
                                        <div className="class-item-text">
                                            <p>Karsan</p>
                                            <span>Reklam5</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={TwentyProject} />
                                        <div className="class-item-text">
                                            <p>Makel</p>
                                            <span>Reklam5</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={TwentyoneProject} />
                                        <div className="class-item-text">
                                            <p>Masaüstü Kırtasiye</p>
                                            <span>Reklam5</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={TwentytwoProject} />
                                        <div className="class-item-text">
                                            <p>Mogul</p>
                                            <span>Reklam5</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={TwentyfourProject} />
                                        <div className="class-item-text">
                                            <p>Trabzonspor</p>
                                            <span>Reklam5</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={TwentyfiveProject} />
                                        <div className="class-item-text">
                                            <p>Warmhaus</p>
                                            <span>Reklam5</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="e-commerce mt-5 moreDetail" id="tabs-2">
                                <div className="col-lg-6 col-md-6">
                                    <div className="class-item d-flex align-items-center">
                                        <img src={path2}/>
                                        <div className="class-item-text">
                                            <p>Anim Living</p>
                                            <span>Path Internet</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={ThreeProject}/>
                                        <div className="class-item-text">
                                            <p>Altın Yatak</p>
                                            <span>Reklam5</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={path4}/>
                                        <div className="class-item-text">
                                            <p>Beddable</p>
                                            <span>Path Internet</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={TenProject}/>
                                        <div className="class-item-text">
                                            <p>Dük Kıravat</p>
                                            <span>Reklam5</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={path6}/>
                                        <div className="class-item-text">
                                            <p>Godiva</p>
                                            <span>Path Internet</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={path5}/>
                                        <div className="class-item-text">
                                            <p>Jr. Sous Chef</p>
                                            <span>Path Internet</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={path3}/>
                                        <div className="class-item-text">
                                            <p>Kiko Milano</p>
                                            <span>Path Internet</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={path7}/>
                                        <div className="class-item-text">
                                            <p>
                                                Nita Toys
                                            </p>
                                            <span>Path Internet</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="class-item d-flex align-items-center">
                                        <img src={TwentysevenProject}/>
                                        <div className="class-item-text">
                                            <p>Inforoom</p>
                                            <span>Freelance</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={SixteenProject}/>
                                        <div className="class-item-text">
                                            <p>İstanbul Akvaryum</p>
                                            <span>Reklam5</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={Itp}/>
                                        <div className="class-item-text">
                                            <p>İstanbul Tourist Pass</p>
                                            <span>Path İnternet</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={path1}/>
                                        <div className="class-item-text">
                                            <p>Kiss the Hippo</p>
                                            <span>Path Internet</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={path8}/>
                                        <div className="class-item-text">
                                            <p>Sazy</p>
                                            <span>Path Internet</span>
                                        </div>
                                    </div>
                                    <div className="class-item d-flex align-items-center">
                                        <img src={TwentysixProject}/>
                                        <div className="class-item-text">
                                            <p>Welder Watch</p>
                                            <span>Reklam5</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="my-own mt-5 d-none moreDetail" id="tabs-3">
                                <div className="col-lg-6 col-md-6">
                                    <div className="class-item d-flex align-items-center">
                                        <img src={TwentythreeProject}/>
                                        <div className="class-item-text">
                                            <p>RC İlan</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="class-item d-flex align-items-center">
                                        <img src={EightProject} />
                                        <div className="class-item-text">
                                            <p>Blog Caddesi</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="more">
                                <button>More</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ThirdContent;
