import React, { Component } from 'react';
import $ from "jquery";
import Slider from "react-slick";
import FirstSlider from './images/html5.jpg';
import SecondSlider from './images/css3.jpg';
import sixSlider from './images/js.jpeg';
import ThirdSlider from './images/jquery.jpg';
import FivethSlider from './images/react-logo-oguzhansengul.jpg';
import FourthSlider from './images/liquid-code.jpg';
import Angular from './images/angular.jpg';
import Typescript from './images/typescript.jpg';
import Tailwind from './images/tailwind.jpg';
import Nodejs from './images/nodejs.jpg';
import Bootstrap from './images/bootstrap.jpg';

class FirstContent extends Component {
    componentDidMount() {
        $(".Headers nav li").each(function (){
            $(this).hover(function (){
                $(this).toggleClass('menu-active')
            })
        })
    }

    render() {

        const settings = {
            dots: true,
            infinite: true,
            arrows: false,
            autoplay: true,
            loop:false,
            lazyLoad: true,
            slidesToShow: 4,
            initialSlide: 1,
            autoplaySpeed: 2000,
            cssEase: "linear",
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 3,
                        initialSlide: 3
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        };

        return (
            <div className="site-section" id="my-information">
                <section className="mini-slider">
                    <div className="container">
                        <div className="row">
                            <div className="text-center">
                                <h4>Technologies I specialize in.</h4>
                                <h2>The areas I specialize in are listed below.
                                </h2>
                            </div>
                            <div id="mini-slider">
                                <Slider {...settings}>
                                    <div className="mini-slider-desc text-center">
                                        <div>
                                            <img src={Angular} alt=""/>
                                            <h3>Angular</h3>
                                        </div>
                                    </div>
                                    <div className="mini-slider-desc text-center">
                                        <div>
                                            <img src={Typescript} alt=""/>
                                            <h3>TypeScript</h3>
                                        </div>
                                    </div>
                                    <div className="mini-slider-desc text-center">
                                        <div>
                                            <img src={FourthSlider} alt=""/>
                                            <h3>Shopify (Liquid Code)</h3>
                                        </div>
                                    </div>
                                    <div className="mini-slider-desc text-center">
                                        <div>
                                            <img src={FivethSlider} alt=""/>
                                            <h3>React</h3>
                                        </div>
                                    </div>
                                    <div className="mini-slider-desc text-center">
                                        <div>
                                            <img src={sixSlider} alt=""/>
                                            <h3>JS</h3>
                                        </div>
                                    </div>
                                    <div className="mini-slider-desc text-center">
                                        <div>
                                            <img src={Nodejs} alt=""/>
                                            <h3>Node.js</h3>
                                        </div>
                                    </div>
                                    <div className="mini-slider-desc text-center">
                                        <div>
                                            <img src={ThirdSlider} alt=""/>
                                            <h3>jQuery</h3>
                                        </div>
                                    </div>
                                    <div className="mini-slider-desc text-center">
                                        <div>
                                            <img src={FirstSlider} alt=""/>
                                            <h3>HTML5</h3>
                                        </div>
                                    </div>
                                    <div className="mini-slider-desc text-center">
                                        <div>
                                            <img src={SecondSlider} alt=""/>
                                            <h3>CSS3</h3>
                                        </div>
                                    </div>
                                    <div className="mini-slider-desc text-center">
                                        <div>
                                            <img src={Tailwind} alt=""/>
                                            <h3>TailWind</h3>
                                        </div>
                                    </div>
                                    <div className="mini-slider-desc text-center">
                                        <div>
                                            <img src={Bootstrap} alt=""/>
                                            <h3>Bootstrap</h3>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        );
    }
}

export default FirstContent;
