import React, { Component } from 'react';
import $ from "jquery";
class FourContent extends Component {
    componentDidMount() {
        var divHeight = $('.works').height()
        $('.livein').css('height', divHeight)
        $('.livein .row').css('height', divHeight)
    }
    render() {
        return (
            <div className="myworks" id="whoami">
                <div className="livein">
                    <div className="container worker">
                        <div className="row align-items-center">
                            <div className="col-lg-7 mx-auto text-center slider-text">
                                <h3>LIVE IN ISTANBUL</h3>
                                <p className="lead mx-auto desc mb-5">I live every moment I will be happy.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="site-section">
                    <div className="container">
                        <div className="row">
                            <h4 className="text-center">Who is Oğuzhan Şengül?</h4>
                            <div className="text-center pt-5">
                                <p>Hi everyone, I was born in 1992. i like travel, camping and especially using a motorcycle.
                                </p>
                                <p>I've always aimed happiness and success in my life. I lived with the belief that some difficulties could be achieve through happiness and always acted in this direction. I have been continue in the software profession  for more than 10 years. Software is more of a passion for me. The fact that it is a field suitable for development in this profession that really fascinates me. Keeping myself updated constantly motivates me.
                                </p>
                                <p>Another source of motivation in my life has been my hobbies. motorcycle, travel, RC Model and e-sport. I have a youtube channel where I share my hobbies.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default FourContent;