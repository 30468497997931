import React, { Component } from 'react';
import Github from './github.png';
class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <footer>
                    <div className="container">
                        <div className="row">
                            <div className="text-center">
                                <div className="footer-decription">
                                    <p className="email-title">E-Mail</p>
                                    <p className="email"><a href="mailto:info@oguzhansengul.com">info@oguzhansengul.com</a></p>
                                </div>
                                <div className="social w-100 text-center">
                                    <div className="social-footer d-inline-block">
                                        <div className="linkedin">
                                            <a href="https://www.linkedin.com/in/o%C4%9Fuzhan-%C5%9F-b4028770/" target="_blank">
                                                <svg aria-hidden="true" focusable="false" data-prefix="fab"
                                                     data-icon="linkedin-in" className="svg-inline--fa fa-linkedin-in fa-w-14"
                                                     role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                    <path fill="currentColor"
                                                          d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
                                                    </path>
                                                </svg>
                                            </a>
                                        </div>
                                        <div className="medium">
                                            <a target="_blank" href="https://medium.com/@oguzhansengul.web">
                                                <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="medium"
                                                     className="svg-inline--fa fa-medium fa-w-14" role="img"
                                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                    <path fill="currentColor"
                                                          d="M0 32v448h448V32H0zm372.2 106.1l-24 23c-2.1 1.6-3.1 4.2-2.7 6.7v169.3c-.4 2.6.6 5.2 2.7 6.7l23.5 23v5.1h-118V367l24.3-23.6c2.4-2.4 2.4-3.1 2.4-6.7V199.8l-67.6 171.6h-9.1L125 199.8v115c-.7 4.8 1 9.7 4.4 13.2l31.6 38.3v5.1H71.2v-5.1l31.6-38.3c3.4-3.5 4.9-8.4 4.1-13.2v-133c.4-3.7-1-7.3-3.8-9.8L75 138.1V133h87.3l67.4 148L289 133.1h83.2v5z"></path>
                                                </svg></a>
                                        </div>
                                        <div className="github">
                                            <a target="_blank" href="https://github.com/rc-oguz">
                                                <img src={Github} alt=""/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="copyright text-center pt-5">
                                    <p>©OĞUZHAN ŞENGÜL, ALL RIGHTS RESERVED.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>

        );
    }
}

export default Footer;